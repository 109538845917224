import React from 'react';
import { openDocument } from '@taktikal/fillandsign';

const ProducerSignupButton = () => {
  const handleOpen = () => {
    openDocument('1aedd3d658b6');
  }

  return <>
    <button
      className="btn btn-big btn-filled"
      onClick={handleOpen}
    >Skrá útgefanda í SFH</button>
  </>
}

export default ProducerSignupButton;