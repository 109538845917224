import React from 'react';
import { openDocument } from '@taktikal/fillandsign';

const SignupButton = () => {
  const handleOpen = () => {
    openDocument('7c582a3acd02');
  }

  return <>
    <button
      className="btn btn-big btn-filled"
      onClick={handleOpen}
    >Fylla út eyðublað</button>
  </>
}

export default SignupButton;