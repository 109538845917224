import React from 'react';
// import dompurify from 'dompurify';

// const defaultOptions = {
//   ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'img'],
//   ALLOWED_ATTR: ['href']
// };

// const sanitize = (dirty) => ({
//   __html: dompurify.sanitize(
//     dirty,
//     // { ...defaultOptions, ...options }
//   )
// });

// TODO: Sanitize. Þetta virkaði ekki þegar ég buildaði. þarf að skoða betur.

const SanitizeHTML = ({ html, options }) => {
  const isReactElement = html?.props !== undefined;

  if (isReactElement) {
    return <>{html}</>
  } else {
    // return <div dangerouslySetInnerHTML={sanitize(html, options)} />
    return <div dangerouslySetInnerHTML={{ __html: html }} />
  }
};

export default SanitizeHTML;